import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import ls from 'local-storage';
import firebase from 'gatsby-plugin-firebase';
import moment from 'moment';
import { navigate } from 'gatsby';

import SEO from '../components/seo';
import Layout from '../components/layout';
import PropertyCardComponent from '../components/PropertyCardComponent';
import { Modal, Col, Row, Descriptions, Button, Skeleton, Divider } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Lightbox from 'react-image-lightbox';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import 'react-image-lightbox/style.css';

const contactPersons = {
  luzon: [
    {
      name: 'Pia Aguirre',
      position: 'Manager - China Markets',
      picture: 'pia.jpg',
      tel_number: '0917 631 4821',
      phone_number: '(027) 503 3618',
      email: 'pia.aguirre@primephilippines.com',
    },
    {
      name: 'Romel Dellosa',
      position: 'Manager - Commercial Property Investments',
      picture: 'mhel.jpg',
      tel_number: '0917 526 6143',
      phone_number: '(027) 959 5962',
      email: 'romel.dellosa@primephilippines.com',
    },
  ],
  visayas: [
    {
      name: 'Sarah Magsakay',
      position: 'Manager - Property Advisory',
      picture: 'sarah.jpg',
      tel_number: '0917 843 6134',
      phone_number: '(032) 230 4930',
      email: 'sarah.magsakay@primephilippines.com',
    },
  ],
  mindanao: [
    {
      name: 'Chai Abaya',
      position: 'Regional Head - Mindanao Operations',
      picture: 'chai.jpg',
      tel_number: '0917 633 0062',
      phone_number: '(082) 324 1086',
      email: 'chai.abaya@primephilippines.com',
    },
  ],
};

const StyledDiv = styled.div`
  margin-top: 64px;
  min-height: calc(100vh - 72px - 64px);
  background-color: ${(props) => (props.pageLoading ? 'white' : '#f2f2f5')};
  padding-top: 40px;
  padding-bottom: 40px;

  .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    margin-left: 0px;
  }

  .property-list {
    margin: auto 20px;
    @media (max-width: 575.98px) {
      margin: auto 20px;
    }

    @media (min-width: 576px) and (max-width: 767.98px) {
      margin: auto 20px;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
      margin: auto 20px;
    }

    @media (min-width: 992px) and (max-width: 1199.98px) {
      margin: auto 16px;
    }

    @media (min-width: 1200px) {
      margin: auto 30px;
    }
  }
`;

const StyledInfoGraphic = styled.div`
  text-align: center;
  font-size: 1.15em;
`;

const StyledContactDetails = styled.div``;

const StyledModal = styled(Modal)`
  table {
    margin-bottom: 0;
  }
  .property-title {
    font-size: 1.25em;
    font-weight: bold;
    line-height: 1;
  }

  .property-type {
    color: #0984e3;
    font-weight: bold;
    font-size: 0.9em;
  }
  .property-landmark {
    opacity: 0.9;
  }

  .employee-pic {
    margin-bottom: 0px;
    max-width: 100px;
  }
`;

function IssueInformation({ typeOfIssue = 1, issueDescription = '' }) {
  const issueColorPalette = ['#27ae60', '#f1c40f', '#d35400'];
  return (
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
      }}
    >
      <FontAwesomeIcon
        color={issueColorPalette[typeOfIssue - 1]}
        icon={typeOfIssue === 1 ? 'check' : 'exclamation-triangle'}
        fixedWidth
        style={{ marginRight: 10, marginLeft: 5 }}
        size="1x"
      />
      <div>
        {issueDescription || 'This property has clean real estate title.'}
      </div>
    </div>
  );
}

function PortalHomePage() {
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [pisModalVisibility, setPisModalVisibility] = useState(false);
  const [isPropertiesLoading, setIsPropertiesLoading] = useState(false);

  const [propertiesList, setPropertiesList] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState({
    id: '1',
    name: '819 EDSA, South Triangle',
    title: 'Commercial Property in South Triangle QC',
    lot_area: '3,300.00 sqm',
    frontage: '23 m',
    type: 'Commercial',
    status: '2-storey bldg',
    landmark: 'near Centris',
    hbu_reco:
      'Broadcast Company auxiliary offices, Logistics/Warehouse, School, Medical Institution',
    content:
      'Located along EDSA in South Triangle QC, this highly-acessible commercial property with existing warehouse is well-suited for logistics center or warehouse facility, auxiliary centers or head office of broadcasting companies, schools or medical institutions.',
    images: [
      'https://firebasestorage.googleapis.com/v0/b/cpi-propwise-portal.appspot.com/o/property-image-placeholder.jpg?alt=media&token=2bd9386a-bd7b-4897-b686-6d01f33054f3',
    ],
    issue_type: 1,
    issue_description: '',
    priority: 2,
    area_scope: 'luzon',
    classification: 'lease',
  });
  const [propertyContactDetails, setPropertyContactDetails] = useState({
    name: 'Romel Dellosa',
    position: 'Manager - Commercial Property Investments',
    picture: 'mhel.jpg',
    tel_number: '0917 526 6143',
    phone_number: '(027) 959 5962',
    email: 'romel.dellosa@primephilippines.com',
  });

  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [isCodeLoading, setIsCodeLoading] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [lightBoxImages, setLightBoxImages] = useState([
    'https://firebasestorage.googleapis.com/v0/b/cpi-propwise-portal.appspot.com/o/property-image-placeholder.jpg?alt=media&token=2bd9386a-bd7b-4897-b686-6d01f33054f3',
  ]);

  const fetchProperties = useCallback(() => {
    setIsPropertiesLoading(true);
    const propertiesRef = firebase.database().ref(`property_collections`);
    propertiesRef
      .once('value')
      .then((snapshot) => {
        if (snapshot.val()) {
          const propertiesData = snapshot.val();
          setPropertiesList(
            propertiesData
              .sort((a, b) => a.priority - b.priority)
              .filter((p) => !p.classification.endsWith('offthemarket'))
          );
        } else {
          console.log('No snapshot');
        }
      })
      .catch((onErr) => {
        console.log(onErr);
      })
      .finally(() => {
        setIsPropertiesLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!isPageLoaded) {
      fetchProperties();

      setIsPageLoaded(true);
      setIsCodeLoading(true);

      const accessCode = ls.get('access_code', null);
      const expiresIn = ls.get('expires_in', null);

      if (accessCode === null || expiresIn === null) {
        navigate('/', { replace: true });
      } else {
        if (accessCode) {
          if (expiresIn) {
            if (!moment().isAfter(moment(expiresIn))) {
              firebase
                .database()
                .ref(`access_codes/${accessCode}`)
                .once('value')
                .then((snapshot) => {
                  if (snapshot.val()) {
                    if (snapshot.val().status !== 'active') navigate('/');
                    else {
                      const logsRef = firebase
                        .database()
                        .ref(`logs/login_auth`);
                      logsRef.push(
                        {
                          code: accessCode,
                          timestamp: moment().format('LLL'),
                        },
                        (status) => {
                          if (status) console.log(status);
                        }
                      );
                    }
                  } else {
                    navigate('/');
                  }
                })
                .catch((onErr) => {
                  navigate('/');
                })
                .finally(() => {
                  setIsCodeLoading(false);
                });
            } else {
              ls.remove('access_code');
              ls.remove('expires_in');
              navigate('/', { replace: true });
            }
          } else {
            ls.remove('access_code');
            ls.remove('expires_in');
            navigate('/', { replace: true });
          }
        } else {
          ls.remove('access_code');
          ls.remove('expires_in');
          navigate('/', { replace: true });
        }
      }
    }
  }, [isPageLoaded, isCodeLoading, fetchProperties]);

  return (
    <Layout>
      <SEO title="Portal Home" />

      {isLightBoxOpen && (
        <Lightbox
          mainSrc={lightBoxImages[photoIndex]}
          nextSrc={lightBoxImages[(photoIndex + 1) % lightBoxImages.length]}
          prevSrc={
            lightBoxImages[
              (photoIndex + lightBoxImages.length - 1) % lightBoxImages.length
            ]
          }
          onCloseRequest={() => {
            setIsLightBoxOpen(false);
          }}
          onMovePrevRequest={() => {
            setPhotoIndex(
              (photoIndex + lightBoxImages.length - 1) % lightBoxImages.length
            );
          }}
          onMoveNextRequest={() => {
            setPhotoIndex((photoIndex + 1) % lightBoxImages.length);
          }}
        />
      )}

      <StyledModal
        closable={true}
        maskClosable={true}
        visible={pisModalVisibility}
        footer={null}
        onCancel={() => {
          setPisModalVisibility(false);
        }}
      >
        <div className="property-type">{selectedProperty.type}</div>
        <div style={{ marginTop: 8, marginBottom: 8 }}>
          <div className="property-title">{selectedProperty.title}</div>
          <div className="property-landmark">{selectedProperty.landmark}</div>
        </div>
        <div>{selectedProperty.content}</div>
        <Descriptions column={2} size="small">
          <Descriptions.Item label="Lot Area">
            {selectedProperty.lot_area}
          </Descriptions.Item>
          <Descriptions.Item label="Frontage">
            {selectedProperty.frontage}
          </Descriptions.Item>
          <Descriptions.Item>
            <IssueInformation
              typeOfIssue={selectedProperty.issue_type}
              issueDescription={selectedProperty.issue_description}
            />
          </Descriptions.Item>
        </Descriptions>

        <div
          style={{
            height: 200,
            position: 'relative',
            overflow: 'hidden',
            backgroundSize: 'cover',
            margin: '16px 0',
            backgroundPosition: 'center',
            backgroundImage: `url('${selectedProperty.images[0]}')`,
            cursor: 'pointer',
          }}
          onClick={() => {
            setIsLightBoxOpen(true);
          }}
        >
          <Button
            type="primary"
            style={{ position: 'absolute', top: 10, right: 10 }}
            onClick={() => {
              setIsLightBoxOpen(true);
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div>
                <b>
                  {selectedProperty
                    ? selectedProperty.images.length > 1
                      ? `${selectedProperty.images.length} PHOTOS`
                      : '1 PHOTO'
                    : '0 PHOTOS'}
                </b>
              </div>
              <FontAwesomeIcon
                icon="images"
                fixedWidth
                style={{ marginLeft: 5 }}
              />
            </div>
          </Button>
        </div>

        <StyledContactDetails>
          <b>
            <p style={{ marginBottom: 6 }}>
              You may contact this person about this property:
            </p>
          </b>

          <Row gutter={[12, 12]}>
            <Col>
              <img
                className="employee-pic"
                src={require(`../../static/sales-team/${propertyContactDetails.picture}`)}
                alt={propertyContactDetails.title}
              />
            </Col>
            <Col>
              <div className="employee-contact-details">
                <div
                  style={{ fontSize: '1.1em', lineHeight: 1, marginBottom: 6 }}
                >
                  <b>{propertyContactDetails.name}</b>
                  <div>{propertyContactDetails.position}</div>
                </div>
                <div style={{ lineHeight: 1.2 }}>
                  <FontAwesomeIcon icon="mobile" fixedWidth />{' '}
                  {propertyContactDetails.tel_number}
                </div>
                <div style={{ lineHeight: 1.2 }}>
                  <FontAwesomeIcon icon="phone" fixedWidth />{' '}
                  {propertyContactDetails.phone_number}
                </div>
                <div style={{ lineHeight: 1.2 }}>
                  <FontAwesomeIcon icon="envelope" fixedWidth />{' '}
                  {propertyContactDetails.email}
                </div>
              </div>
            </Col>
          </Row>
        </StyledContactDetails>
      </StyledModal>

      <StyledDiv pageLoading={isCodeLoading}>
        {isPageLoaded ? (
          !isCodeLoading ? (
            <div>
              <StyledInfoGraphic>
                <Row gutter={[16, 24]} justify="center">
                  <Col xs={23} sm={23} md={22} lg={20} xl={16} xxl={16}>
                    <h1>Why should I buy property?</h1>
                    <p>
                      At{' '}
                      <a href="https://www.primephilippines.com">
                        PRIME Philippines
                      </a>
                      , we understand that acquiring property without a full
                      undestanding of the necessary equirements and subsequent
                      implications can be daunting. We get it: making large
                      investments isn't wise without an equally broad
                      understanding of facts and figures.
                    </p>

                    <p>
                      With the plethora of properties available on the market,
                      how can one find the best deals? How can you take
                      advantage of the current market in the most efficient and
                      successful way possible?
                    </p>
                    <p>
                      PRIME Philippines believes in making your journey to find
                      the right assets to acquire as convenient as possible. Our
                      team of seasoned advisors are here to provide you{' '}
                      <a href="https://www.primephilippines.com/our-expertise/experienced/end-to-end-real-estate-services">
                        end-to-end services
                      </a>{' '}
                      for all your real estate needs, ensuring as much as
                      possible the most hassle-free property buying experience.
                    </p>

                    <p>
                      It all begins with taking the first step. Our team has
                      compiled a{' '}
                      <AnchorLink href="#premium-properties">
                        collection of premium properties
                      </AnchorLink>{' '}
                      below, all newly sourced and entrusted to us by
                      highly-motivated sellers, representing some of the best
                      opportunities presently available in the market.
                    </p>
                    <p id="premium-properties">
                      We invite you to peruse this selected portfolio, inquire
                      with us to start negotiations, and take advantage of these
                      property bargains!
                    </p>
                  </Col>
                </Row>
              </StyledInfoGraphic>
              <br />
              <div className="property-list">
                {!isPropertiesLoading ? (
                  <Row gutter={[16, 24]} style={{ marginBottom: 0 }}>
                    {propertiesList.map((value) => (
                      <Col
                        key={value.id}
                        xs={24}
                        sm={24}
                        md={8}
                        lg={8}
                        xl={6}
                        xxl={6}
                        style={{ paddingTop: 8, paddingBottom: 12 }}
                      >
                        <PropertyCardComponent
                          data={value}
                          action={{
                            title: 'View Details',
                            onClick: () => {
                              setPropertyContactDetails(
                                contactPersons[value.area_scope][
                                  Math.floor(
                                    Math.random() *
                                      contactPersons[value.area_scope].length
                                  )
                                ]
                              );
                              setLightBoxImages(value.images);
                              setSelectedProperty(value);
                              setPisModalVisibility(true);

                              const accessCode = ls.get('access_code', null);
                              const logsRef = firebase
                                .database()
                                .ref(`logs/views`);
                              logsRef.push(
                                {
                                  property_id: value.id,
                                  property_title: value.title,
                                  code: accessCode,
                                  timestamp: moment().format('LLL'),
                                },
                                (status) => {
                                  if (status) console.log(status);
                                }
                              );
                            },
                          }}
                        />
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <div style={{ fontSize: '2em', textAlign: 'center' }}>
                    <FontAwesomeIcon icon="spinner" spin /> Loading your
                    properties
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              style={{
                maxWidth: '90%',
                margin: '0 auto',
              }}
            >
              <Skeleton active />
              <Skeleton.Button active size="small" shape="square" />
              <Divider />
              <Skeleton active />
              <Skeleton.Button active size="small" shape="square" />
            </div>
          )
        ) : null}
      </StyledDiv>
    </Layout>
  );
}

export default PortalHomePage;
