import React from 'react';
import styled from 'styled-components';

import { Button, Descriptions } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledPropertyCard = styled.div`
  .img-preview {
    position: relative;
    background-image: url('${(props) => props.imgSrc}');
    background-position: center;
    background-size: cover;
    min-height: 200px;
    > img {
      margin-bottom: -20%;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
    }
    :after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.6) 100%
      );
    }
  }
`;

const StyledDetailsCard = styled.div`
  position: relative;
  padding: 14px;
  background-color: white;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: -50px;
  table {
    margin-bottom: 16px;
  }

  td {
    padding-top: 8px;
  }

  .card-action-btns {
    margin-bottom: 4px;
    text-align: center;
  }

  .property-title {
    font-size: 1.25em;
    font-weight: bold;
    line-height: 1;
  }

  .property-type {
    color: #0984e3;
    font-weight: bold;
    font-size: 0.9em;
  }

  .property-landmark {
    opacity: 0.9;
  }
`;

function IssueInformation({ typeOfIssue = 1, issueDescription = '' }) {
  const issueColorPalette = ['#27ae60', '#f1c40f', '#d35400'];
  return (
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
      }}
    >
      <FontAwesomeIcon
        color={issueColorPalette[typeOfIssue - 1]}
        icon={typeOfIssue === 1 ? 'check' : 'exclamation-triangle'}
        fixedWidth
        style={{ marginRight: 10, marginLeft: 5 }}
        size="1x"
      />
      <div>
        {issueDescription || 'This property has clean real estate title.'}
      </div>
    </div>
  );
}

function PropertyCard({ data, action }) {
  return (
    <StyledPropertyCard imgSrc={data.images[0]}>
      <div className="img-preview"></div>

      <StyledDetailsCard>
        <div className="property-type">{data.type}</div>
        <div style={{ marginTop: 8 }}>
          <div className="property-title">{data.title}</div>
          <div className="property-landmark">{data.landmark}</div>
        </div>

        <Descriptions column={1} size="small">
          <Descriptions.Item label="Lot Area">
            {data.lot_area}
          </Descriptions.Item>
          <Descriptions.Item label="Frontage">
            {data.frontage}
          </Descriptions.Item>
          <Descriptions.Item>
            <IssueInformation
              typeOfIssue={data.issue_type}
              issueDescription={data.issue_description}
            />
          </Descriptions.Item>
        </Descriptions>

        <div className="card-action-btns">
          <Button type="primary" onClick={action.onClick}>
            <b>{action.title}</b>
          </Button>
        </div>
      </StyledDetailsCard>
    </StyledPropertyCard>
  );
}

export default PropertyCard;
